.category-cell {
  button {
    padding: 0px 6px;
  }
}

.prices {
  font-size: 12px;
  .rs-radio-group-picker {
    .rs-radio-checker > label {
      padding: 3px 8px !important;
      font-size: 12px;
    }
  }
}
.rs-radio-group-picker{
  background-color: white;
}
.product_variation {
  padding-right: 80px;
}
.sortableHelper {
  z-index: 2000;
}
.sortable-images {
  z-index: 999999;
}

.product-form,.product_variation {
  .rs-panel-body,
  .rs-panel-heading {
    padding: 10px;
  }
  .rs-panel-heading {
    font-size: 12px !important;
  }
  .rs-list-item {
    background-color: #fafafa !important;
  }
  .rs-panel-collapsible .rs-panel-heading:before {
    top: 10px;
  }
  .rs-panel-title {
    font-weight: 700;
  }

  .drag-n-order {
    width: 35px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -25px;
    text-align: center;
    cursor: -webkit-grab;
  }
  .delete {
    cursor: pointer;
    position: absolute;
    width: 28px;
    font-size: 20px;
    right: 10px;
    top: 5px;
    &:hover {
      color: red;
    }
  }
  .field-toogle{
    .rs-form-control-wrapper {
      width: initial;
    }
  }
  .rs-form-control-wrapper {
    width: 100%;
  }


  .images-container {
    .image-wrap {
      position: relative;
      .trash {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      .edit {
        position: absolute;
        top: 5px;
        left: 5px;
      }
      .move-right {
        position: absolute;
        bottom: 5px;
        right: 5px;
      }
      .move-left {
        position: absolute;
        bottom: 5px;
        left: 5px;
      }
      button {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s;
      }
      &:hover {
        button,
        .drag {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}


