body {
    background-color: $bg-gray;
}

#content-container {
    transition: all 0.2s ease-in, width 0.2s ease-in;
    padding-top: 80px;
    padding-right: 20px;
    &.menu-expanded {
        padding-left: 220px;
    }
    &.menu-closed {
        padding-left: 80px;
    }
}

