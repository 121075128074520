.rs-picker-default,
.rs-input-group,
.rs-picker-toggle,
.rs-input,
input {
  border-radius: 0 !important;
}

.addon-text-field input {
  border-radius: 0 !important;
}

.rs-input-group-sm .rs-input-number-touchspin-up,
.rs-input-group-sm .rs-input-number-touchspin-down {
  height: 12.5px;
}

.mapper-form {
  .rs-list {
    padding-bottom: 200px;
  }

  .scraped-images-container {
    background: rgba(black, 0.02);
  }

  .image-mapper-wrap {
    .zoom {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .upload {
      position: absolute;
      top: 8px;
      right: 33px;
    }

    button {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
}

.mapper-popover {
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  max-width: 500px !important;
}

.mapper-existing-products {
  .section-mapper {
    position: relative;
  }

  .rs-tag {
    position: relative;
  }

  .rs-tag-text {
    display: flex;
    align-items: center;
  }

  /*.btn-add {
    position: absolute;
    top: 0;
    right: 0;
  }
  .btn-merge {
    position: absolute;
    top: 0;
    right: 30px;
  }*/
}

.abs-image {
  position: absolute;
  top: 300px;
  right: 20px;

  .large-thumb {
    width: 300px;
  }
}

.popover-img {
  /*max-width: 500px !important;
  height: auto;*/
}

.attributes-mapper-internal {
  //min-width: 1500px;
  border: 2px solid rgba(black, 0.3);

  th,
  td {
    border: 1px solid rgba(black, 0.3);
  }

  td {
    border: 1px solid #e3e3e3;

    &.att_category {
      width: 450px;
      max-width: 450px;
    }

    &.att {
      width: 325px;
      max-width: 325px;
    }

    &.att_value {
      width: 250px;
      max-width: 250px;

      .tox-tinymce {
        width: 250px !important;

        &.tox-fullscreen {
          width: 100% !important;
        }
      }

      .rs-radio-group-inline {
        width: 100%;
      }

      .rs-radio {
        width: 33%;
        text-align: center;
      }

      .rs-input-group-addon {
        background: #e8e8e8;
        border-radius: 0;
      }

      .rs-radio-checked {
        label {
          font-weight: bold !important;
          color: #c21e1e !important;
        }
      }

    }

    &.att_qty {
      width: 70px;
      max-width: 70px;
      position: relative;

      .fill-gray {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #e8e8e8;
        top: 0;
        left: 0;
      }

      .rs-form-control-wrapper {
        line-height: 1;
        vertical-align: middle;
      }
    }

    &.att_actions {
      width: 140px;
      max-width: 140px;
      text-align: right;
    }

    &.mapped_from {
      width: 300px;
      max-width: 300px;
      position: relative;
      padding-top: 15px !important;
      padding-right: 15px;

      .shop-name {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 70%;
      }
    }

    &.mapped_from {
      width: 300px;
      max-width: 300px;
      position: relative;
      padding-top: 15px !important;
      padding-right: 15px;

      .shop-name {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 70%;
      }
    }

    &.ai_value {
      width: 150px;
      max-width: 150px;
      position: relative;
      // padding-top: 15px !important;
      // padding-right: 15px;
    }

    &.ai_note {
      width: 150px;
      max-width: 150px;
      position: relative;
      // padding-top: 15px !important;
      // padding-right: 15px;
    }

  }

  tr {
    &.mapped-bg {
      background-color: #598905;

      td {
        border: 1px solid #598905;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white;
      }

      .att_qty {
        .fill-gray {
          background: #598905;
        }
      }
    }

    &.filledin-bg {
      background-color: #61930a;

      td {
        border: 1px solid #61930a;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white;
      }

      .att_qty {
        .fill-gray {
          background: #61930a;
        }
      }
    }

    &.featured {
      background-color: #94cff3;

      td {
        border: 1px solid #94cff3;
        padding-top: 5px;
        padding-bottom: 5px;
        color: black;
      }

      .att_qty {
        .fill-gray {
          background: #94cff3;
        }
      }
    }

    &.filter {
      background-color: #f0cf63;

      td {
        border: 1px solid #f0cf63;
        padding-top: 5px;
        padding-bottom: 5px;
        color: black;
      }

      .att_qty {
        .fill-gray {
          background: #f0cf63;
        }
      }
    }
  }

  .bg-gray {
    background-color: rgba(black, 0.2);
  }

  .bg-light-gray {
    background-color: rgba(black, 0.05);
  }

  .att-actions {
    position: absolute;
    right: 2px;
    top: 1px
  }
}

.attributes-mapper {
  min-width: 1500px;
  border: 2px solid rgba(black, 0.3);

  th,
  td {
    border: 1px solid rgba(black, 0.3);
  }

  td {
    max-width: 800px;
    min-width: 200px;
    padding-right: 50px !important;
    position: relative;

    &.mapped {
      background: #598905;
      color: white
    }

    &.disabled {
      background: rgba(gray, 0.3);
      color: rgba(gray, 0.5)
    }
  }

  .bg-gray {
    background-color: rgba(black, 0.2);
  }

  .bg-light-gray {
    background-color: rgba(black, 0.05);
  }

  .att-actions {
    position: absolute;
    right: 2px;
    top: 1px
  }
}