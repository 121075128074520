.status-badge {
  line-height: 1;
  padding: 2px 6px 3px;
  font-size: 10px;

  &.active,
  &.started,
  &.ok {
    background-color: $success;
  }

  &.draft,&.checked {
    background-color: gray;
  }

  &.disabled,
  &.canceled,
  &.declined,
  &.failed,
  &.error {
    background-color: $danger;
  }

  &.pending,
  &.warning,
  &.paused,
  &.daily_budg {
    background-color: $warning;
  }

  &.rescheduled {
    background-color: $info;
  }

  &.archived,&.completed {
    background-color: #3b3b3b;
  }

  &.new {
    background-color: $success;
  }

  &.mapped {
    background-color: $danger;
  }

  &.ended {
    background-color: gray;
  }
}

.rs-drawer-body {
  margin: 15px 20px;
}

textarea.rs-input {
  min-width: 200px;
}

.rs-drawer {
  .rs-drawer-header {
    .rs-drawer-title {
      font-size: 18px;
    }
  }

  .drawer-subtitle {
    font-size: 10px;
  }

  .drawer-toptitle {
    font-size: 10px;
  }

  .rs-input-group-addon {
    font-size: 12px;
  }
}

.rs-picker-toggle.rs-btn-sm {
  font-size: 12px;
}

.rs-form-group {
  position: relative;
}

.inline-radio {
  .rs-radio-checker>label {
    font-size: 12px;
    padding: 5px 10px;

  }

  span {
    padding: 6px 6px 6px 10px;
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
  }

  .rs-radio-checked {
    label {
      font-weight: bold !important;
    }
  }
}

.rs-form-group {
  label {
    display: block;
  }

  .rs-form-control-wrapper {
    display: inline-block;
  }
}

.rs-divider-horizontal.rs-divider-with-text {

  //padding: 0 15px;
  .rs-divider-inner-text {
    font-weight: bold;
    font-size: 14px;
  }

  &:before,
  &:after {
    border-top: 1px solid $primary;
  }
}

.show-action-icons-hover {

  &.action-icons,
  .action-icons {
    .rs-btn-icon {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
    }
  }

  &:hover,
  &:focus {

    &.action-icons,
    .action-icons {
      .rs-btn-icon {
        opacity: 1;
      }
    }
  }
}

.rs-input-group-addon {
  border-radius: 6px;
}

.addon-text-field {
  input {
    border-radius: 6px !important;
  }
}

.field-toogle,
.rs-control-label {
  .rs-help-block-tooltip {
    margin-top: 2px;
  }
}

.rs-help-block-tooltip {
  margin-top: 7px;
  line-height: 1;
  opacity: 0.3;
}

.rs-input-group {
  overflow: auto;
}

.rs-input-group.rs-input-group-inside {
  .rs-input {
    padding-right: 50px;
  }

  .rs-input-group-addon {
    opacity: 0.5;
    padding: 7px 13px 8px 13px;
  }

  &.addon-right {
    .rs-input-group-addon {
      border-left: none;
      left: auto;
      right: 0;
    }
  }

  &.addon-left {
    .rs-input {
      padding-right: 5px;
      padding-left: 45px;
    }
  }
}

.rs-drawer-footer {
  text-align: left;
  padding-bottom: 20px;
}

.rs-table-cell {
  .rs-btn-link {
    font-size: 12px;
  }
}

.rs-tag {
  display: block;
}

.clickable {
  cursor: pointer;
}

.rs-table {
  z-index: 1;
}



.image-wrap {
  z-index: 1111 !important;

  .img-grab {
    cursor: pointer;

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }


  position: relative;

  .trash {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .cut {
    position: absolute;
    top: 30px;
    right: 5px;
  }

  .edit {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .move-right {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .move-left {
    position: absolute;
    bottom: 5px;
    left: 5px;
  }

  button {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {

    button,
    .drag {
      visibility: visible;
      opacity: 1;
    }
  }
}







.documents-container {
  .document-wrap {
    position: relative;

    .img-thumbnail {
      min-height: 60px;
    }

    .trash {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .edit {
      position: absolute;
      top: 5px;
      left: 5px;
    }

    .move-right {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }

    .move-left {
      position: absolute;
      bottom: 5px;
      left: 5px;
    }

    button {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s;
    }

    button.upload {
      visibility: visible;
      opacity: 1;
      margin-left: 4px;
    }

    &:hover {

      button,
      .drag {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}





.small-thumb {
  width: 50px;
  display: inline-block;
  padding-right: 10px;
  vertical-align: middle;
}

.medium-thumb {
  width: 100px;
  display: inline-block;
  padding-right: 10px;
  vertical-align: middle;
}

.large-thumb {
  width: 180px;
  display: inline-block;
  padding-right: 10px;
  vertical-align: middle;
}

.rs-table-cell-header-icon-sort {
  color: #a7a7a7;
}

.addon-text-field {
  .rs-form-control-wrapper {
    vertical-align: middle;
  }

  input {
    height: 25px;
  }
}

.rs-panel.filters-wrap {
  .rs-panel-heading {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background: rgba(#d3d3d3, 0.1);
    max-width: 150px;
    padding: 6px 20px;

    //margin-left: auto;
    &:before {
      top: 7px;
    }

    .rs-panel-title {
      font-size: 12px;
    }
  }

  .rs-panel-body {
    background: rgba(#d3d3d3, 0.1);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 10px 20px;
  }

  &.rs-panel-in {
    max-width: initial;

    .rs-panel-heading {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  label {
    color: #a0a0a0;
    font-weight: 400;
  }

  .field-toogle {
    padding-top: 5px;
  }

  .rs-input-group-addon {
    background-color: white;
    color: #a0a0a0;
  }

  .rs-picker-toggle-placeholder {
    color: #a0a0a0;
  }

  input {
    font-weight: bold;
  }
}

.wrap-text {
  white-space: normal;
}

/*
.rs-table {
  .rs-table-row:nth-of-type(2n) {
    background: #fafafa;
    .rs-table-cell {
      background: #fafafa;
    }
  }
}*/

.rs-table-word-wrap .rs-table-cell-content {
  //display: flex;
  //align-items: center;
}

.rs-table-row-expanded {
  overflow-y: auto !important;
  background-color: #f2f2f2;
}

.rs-table-word-wrap .rs-table-cell.text-center .rs-table-cell-content {
  justify-content: center;
}

.rs-table-word-wrap .rs-table-cell-header.text-center .rs-table-cell-content {
  justify-content: center;
}

.rs-drawer-left.rs-drawer-lg,
.rs-drawer-right.rs-drawer-lg {
  width: 1200px;
}

.drawer-loader {
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 999999;

  .loader-wrap {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    width: 74px;
  }
}

.mce-content-body {
  font-size: 12px;
}

.rs-form-vertical .rs-form-group .rs-control-label {
  position: relative;
}

.rs-control-label .rs-help-block-tooltip {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
  font-size: 16px;
}

.rs-tooltip {
  font-size: 18px;
}

.rs-tooltip .rs-tooltip-inner {
  padding: 8px 15px;
  max-width: 400px;
}

.rs-table-treetable .rs-table-row[data-depth="0"] {
  font-weight: bold !important;
  font-size: 120%;
}

.rs-table-treetable .rs-table-row[data-depth="1"] {
  font-weight: bold !important;
  //font-size: 110%;
}

.custom-number-field {}


.big_counter {
  position: relative;
  cursor: pointer;
  &.selected {
    cursor: inherit;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: $primary;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: $primary;
    }
  }

  .rs-panel-heading {
    font-size: 12px !important;
    //font-weight: bold;
    padding: 15px 15px 5px;
  }

  .rs-panel-body {
    font-size: 30px;
    font-weight: bold;
  }
}