.login-page {
    height: 100vh;

    &.login-bg {
        background: url("../images/priceteka-bg.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    
    }
}
